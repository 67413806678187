
  import linkClickRouting from '@/mixins/linkClickRouting';
  export default {
    name: 'RichText',
    mixins: [linkClickRouting],
    props: {
      content: {
        type: String,
        required: false,
        default: null,
      },
    },
    methods: {
      formatContent(text) {
        return text.replace(/\n/g, '  \n'); // Convert single line breaks into Markdown line breaks
      },
    },
  };
